export const helpersBodyshopForm = [
  {
    title: `Подписание договора`,
    description: `Обсуждение условий и положений`,
  },
  {
    title: `Требования`,
    description: `Ваши требования к кандидатам`,
  },
  {
    title: `Подбор`,
    description: `Процесс подбора специалистов`,
  },
  {
    title: `Резюме`,
    description: `Мы предоставляем резюме кандидатов, учитывая вашу потребность`,
  },
  {
    title: `Старт проекта`,
    description: `Получение доступов в кратчайшие сроки`,
  },
  {
    title: `Обратная связь`,
    description: `Регулярная отчётность о процессе проекта`,
  },
];
