import architecture from 'assets/bodyShop/Isometricks/architecture.png';
import architecture_tablet from 'assets/bodyShop/Isometricks/architecture_tablet.png';
import architecture_mobile from 'assets/bodyShop/Isometricks/architecture_mobile.png';
import pm from 'assets/bodyShop/Isometricks/pm.svg';
import pm_tablet from 'assets/bodyShop/Isometricks/pm_tablet.svg';
import pm_mobile from 'assets/bodyShop/Isometricks/pm_mobile.svg';
import qa from 'assets/bodyShop/Isometricks/qa.svg';
import qa_tablet from 'assets/bodyShop/Isometricks/qa_tablet.svg';
import qa_mobile from 'assets/bodyShop/Isometricks/qa_mobile.svg';
import analysis from 'assets/bodyShop/Isometricks/analysis.svg';
import analysis_tablet from 'assets/bodyShop/Isometricks/analysis_tablet.svg';
import analysis_mobile from 'assets/bodyShop/Isometricks/analysis_mobile.svg';
import fe from 'assets/bodyShop/Isometricks/fe.svg';
import fe_tablet from 'assets/bodyShop/Isometricks/fe_tablet.svg';
import fe_mobile from 'assets/bodyShop/Isometricks/fe_mobile.svg';
import be from 'assets/bodyShop/Isometricks/be.svg';
import be_tablet from 'assets/bodyShop/Isometricks/be_tablet.svg';
import be_mobile from 'assets/bodyShop/Isometricks/be_mobile.svg';
import mobile from 'assets/bodyShop/Isometricks/mobile.svg';
import mobile_tablet from 'assets/bodyShop/Isometricks/mobile_tablet.svg';
import mobile_mobile from 'assets/bodyShop/Isometricks/mobile_mobile.svg';
import iso1C from 'assets/bodyShop/Isometricks/1C.svg';
import iso1C_tablet from 'assets/bodyShop/Isometricks/1C_tablet.svg';
import iso1C_mobile from 'assets/bodyShop/Isometricks/1C_mobile.svg';
import design from 'assets/bodyShop/Isometricks/design.png';
import design_tablet from 'assets/bodyShop/Isometricks/design_tablet.png';
import design_mobile from 'assets/bodyShop/Isometricks/design_mobile.png';
import devops from 'assets/bodyShop/Isometricks/devops.svg';
import devops_tablet from 'assets/bodyShop/Isometricks/devops_tablet.svg';
import devops_mobile from 'assets/bodyShop/Isometricks/devops_mobile.svg';

export const skillsLabels = ['Анализ и управление', 'Разработка', 'Инфраструктура и поддержка'];

export const tabsData = [
  [
    {
      title: 'Architecture',
      subTitle: '20+ сотрудников',
      tags: ['Software Architect', 'Solutions Architect', 'Enterprise Architect'],
      bgImg: { xxl: architecture, md: architecture_tablet, mobile: architecture_mobile },
    },
    {
      title: 'Project Management',
      subTitle: '50+ сотрудников',
      tags: ['Project Managers', 'Scrum Masters', 'Project Coordinators'],
      bgImg: { xxl: pm, md: pm_tablet, mobile: pm_mobile },
    },
    {
      title: 'Quality assurance',
      subTitle: '450+ сотрудников',
      tags: ['Manual', 'Automation', 'Performance'],
      bgImg: { xxl: qa, md: qa_tablet, mobile: qa_mobile },
    },
    {
      title: 'Analysis',
      subTitle: '350+ сотрудников',
      tags: ['Business', 'System', 'BI'],
      bgImg: { laptop: analysis, md: analysis_tablet, mobile: analysis_mobile },
      sxTags: { maxW: '220px' },
    },
  ],
  [
    {
      title: 'Frontend',
      subTitle: '450+ сотрудников',
      tags: ['React', 'Angular', 'Vue.js', 'JavaScript'],
      bgImg: { xxl: fe, md: fe_tablet, mobile: fe_mobile },
    },
    {
      title: 'Backend',
      subTitle: '350+ сотрудников',
      tags: ['Java', '.Net', 'Python', 'Golang', 'PHP', 'C++'],
      bgImg: { xxl: be, md: be_tablet, mobile: be_mobile },
    },
    {
      title: 'Mobile',
      subTitle: '130+ сотрудников',
      tags: ['Android', 'IOS'],
      bgImg: { xxl: mobile, md: mobile_tablet, mobile: mobile_mobile },
    },
    {
      title: '1C',
      subTitle: '50+ сотрудников',
      tags: ['1C ERP 2.0', '1C ZUP Corp', '1C DO Corp', '1C Integration', 'Bitrix 24'],
      bgImg: { xxl: iso1C, md: iso1C_tablet, mobile: iso1C_mobile },
      sxTags: { flexDir: { mobile: 'row', tablet: 'column', laptop: 'row' } },
      sxContainer: { maxW: { mobile: '220px', laptop: '280px' } },
    },
  ],
  [
    {
      title: 'Design',
      subTitle: '50+ сотрудников',
      tags: ['UX Engineers', 'UI Design', 'Graphic Designers', '3D Designers'],
      bgImg: { xxl: design, md: design_tablet, mobile: design_mobile },
    },
    {
      title: 'DevOps',
      subTitle: '50+ сотрудников',
      tags: ['DevOps Engineers', 'DevSecOps', 'Cloud Engineers', 'SRE'],
      bgImg: { mg: devops, md: devops_mobile, tablet: devops_tablet, mobile: devops_mobile },
      sxTags: { flexDir: { mobile: 'column', laptop: 'row' } },
    },
  ],
];
