import React, { useState } from 'react';
import { Grid, Stack } from '@chakra-ui/react';

import SkillCard from '~entities/SkillCard';
import BlockHeader from 'shared/BlockHeader';
import BlockWrapper from 'shared/BlockWrapper';
import Switcher from 'shared/Switcher';

import { skillsLabels, tabsData } from 'constants/bodyshop/skillsAndCompetionsData';

export default function SkillsAndCompetions() {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = index => {
    setTabIndex(index);
  };

  return (
    <BlockWrapper>
      <BlockHeader>
        Навыки и компетенции
        <br /> наших специалистов
      </BlockHeader>
      <Stack w="full" gap={{ laptop: '28px', mobile: '20px' }}>
        <Switcher
          labels={skillsLabels}
          activeLabel={skillsLabels[tabIndex]}
          onLabelClick={(_, i) => handleTabsChange(i)}
          w="full"
        />
        <Grid gridTemplateColumns={{ tablet: '1fr 1fr' }} gap={{ laptop: '20px', mobile: '16px' }}>
          {tabsData[tabIndex].map((data, i) => (
            <SkillCard key={i} {...data} />
          ))}
        </Grid>
      </Stack>
    </BlockWrapper>
  );
}
