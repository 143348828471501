import { HStack, Stack, Text } from '@chakra-ui/react';

import React from 'react';
import Tag from 'shared/Tag';

const DomainCard = ({ title, subTitle, tags, bgImg, sxContainer = null, sxTags = null }) => {
  return (
    <Stack
      p={{ laptop: '48px', tablet: '28px', mobile: '20px' }}
      h={{ laptop: '426px', tablet: '360px', mobile: '272px' }}
      w="full"
      borderRadius={{ laptop: '48px', mobile: '24px' }}
      bgColor="bg.gray"
      flexDir="row"
      pos="relative"
      overflow="hidden"
      bgImage={bgImg}
      bgRepeat="no-repeat"
      bgPos="right bottom"
    >
      <Stack
        gap={{ laptop: '36px', mobile: '24px' }}
        w="full"
        maxW={{ laptop: '300px', tablet: '165px', mobile: '192px' }}
        zIndex={1}
        sx={sxContainer}
      >
        <Stack w="full" gap="8px">
          <Text as="h3" fontSize={{ laptop: '48px', mobile: '24px' }}>
            {title}
          </Text>
          <Text textStyle="h1" fontWeight={500} fontSize={{ laptop: '24px' }}>
            {subTitle}
          </Text>
        </Stack>
        <HStack w="full" gap="8px" flexWrap="wrap" maxW="280px" align="start" {...sxTags}>
          {tags.map((tag, i) => (
            <Tag key={i} textStyle="h2" whiteSpace="nowrap" fontWeight={500}>
              {tag}
            </Tag>
          ))}
        </HStack>
      </Stack>
    </Stack>
  );
};
export default DomainCard;
