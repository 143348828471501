import React, { memo } from 'react';

import Reviews from 'widgets/Reviews';
import Projects from 'widgets/Projects';
import Companies from 'widgets/Companies';
import ContactUs from 'widgets/ContactUs';
import Greetings from 'widgets/Greetings';
import Advantages from 'widgets/Advantages';
import TechExpert from 'widgets/TechExpert';
import Specialists from 'widgets/Specialists';
import Cooperation from 'widgets/Cooperation';
import PageMetaData from 'widgets/PageMetaData';
import DomainExpertise from 'widgets/DomainExpertise';
import SkillsAndCompetions from 'widgets/SkillsAndCompetions';
import BlockHeader from 'shared/BlockHeader';
import Wrapper from 'shared/Wrapper';

import { cooperations } from 'constants/cooperations';
import { projectsData } from 'constants/bodyshop/projects';
import { bodyshopAdvantagesData } from 'constants/advantages';
import { bodyshopGreetingsData } from 'constants/greetingsData';
import { helpersBodyshopForm } from 'constants/bodyshop/helpersForm';
import { bodyshopDomainExpertiseData } from 'constants/domainExpertise';

const BodyshopPage = () => (
  <>
    <PageMetaData
      title="Расширение ИТ-команды — ASTON"
      description="Усилим вашу команду нашими ИТ-специалистами в областях разработки, анализа, управления, сопровождения. Подберем кандидатов за три дня."
      img={{
        src: 'https://storage.yandexcloud.net/dev.astonsite.s3backet/astondevs/metaPreviews/software-team-staff-augmentation.png',
        alt: 'Превью для страницы Расширение команды',
      }}
    />
    <Wrapper pt={{ desktop: '204px', laptop: '147px', tablet: '160px', mobile: '148px' }}>
      <Greetings {...bodyshopGreetingsData} />
      <Companies />
      <DomainExpertise {...bodyshopDomainExpertiseData} />
      <SkillsAndCompetions />
      {/* @ts-ignore */}
      <Advantages {...bodyshopAdvantagesData} />
      {/* @ts-ignore */}
      <Projects HeaderRender={<BlockHeader>Реализованные проекты</BlockHeader>} {...projectsData} />
      <TechExpert />
      <Specialists />
      <Reviews />
      <Cooperation
        HeaderRender={
          <BlockHeader>
            Выберите подходящую <br /> модель сотрудничества
          </BlockHeader>
        }
        cardsData={cooperations.filter(data => data.title !== 'Расширение команды')}
        showButton={false}
      />
      <ContactUs helpersForm={helpersBodyshopForm} />
    </Wrapper>
  </>
);

export default memo(BodyshopPage);
