import { Box, Stack, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import React from 'react';

export default function Switcher({
  labels = null,
  onLabelClick = null,
  activeLabel = null,
  btnSx = null,
  ...props
}) {
  return (
    <Stack
      direction={{ tablet: 'row', mobile: 'column' }}
      as="ul"
      p="4px"
      spacing={{ tablet: '20px', mobile: '10px' }}
      border="1.6px solid"
      borderColor="bg.black"
      borderRadius={{ tablet: '48px', mobile: '28px' }}
      w={{ tablet: 'fit-content', mobile: 'full' }}
      listStyleType="none"
      userSelect="none"
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
      {...props}
    >
      {labels?.map((label, i) => (
        <Text
          key={i}
          as="li"
          pos="relative"
          textStyle="h2"
          flexShrink="0"
          fontSize={{ laptop: '18px', tablet: '16px', mobile: '12px' }}
          onClick={() => onLabelClick && onLabelClick(label, i)}
          cursor="pointer"
          p={{ desktop: '12px 24px', tablet: '12px 18px', mobile: '12px' }}
          {...btnSx}
        >
          <Text pos="relative" zIndex={10}>
            {label}
          </Text>
          {label === activeLabel ? (
            <Box
              as={motion.div}
              bgColor="bg.accent"
              layoutId="underline"
              h="full"
              pos="absolute"
              borderRadius="40px"
              top={0}
              left={0}
              bottom={0}
              right={0}
              zIndex={5}
            />
          ) : null}
        </Text>
      ))}
    </Stack>
  );
}
